<!-- @format -->

<template>
  <router-view></router-view>

  <!-- <transition name="moveInUp">
            <router-view style="height: 100%;"></router-view>
        </transition> -->

  <!-- <notifications /> -->

  <loading-vue id="loading"></loading-vue>
</template>

<script>
import LoadingVue from "./components/Loading.vue";
import main from "./http/request/main";
import auth from "./http/request/auth";
//import eventsHub2 from "./eventsHub2";
import signalr from "./hub/index";

export default {
  name: "App",
  components: {
    /* main,
        tesla, */ LoadingVue,
  },
  data() {
    return {};
  },

  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        // Tab is hidden
      } else {
        // Tab is visible
        this.getDomainSettingData();
      }
    },

    getDomainSettingData() {
      this.$store
        .dispatch("configModule/getDomainSettingTreeSport")
        .then((res) => {
          if (res.status == 200) {
            if (res.data.setting.siteSuspended == true) {
              localStorage.setItem("siteSuspended", true);
              this.$router.push("/under-maintenance");
            } else {
              //console.log(res);
              document
                .getElementById("_favicon")
                .setAttribute(
                  "href",
                  this.$store.getters["configModule/domainSettings"]?.favicon
                );

              document.body.style.backgroundColor =
                this.$store.getters["configModule/domainSettings"]?.bodyBgColor;

              //localStorage.setItem("siteSuspended", false);
            }
            //} else {
            //  localStorage.setItem("siteSuspended", true);
            //  this.$router.push("/under-maintenance");
          }
        })
        .catch((error) => {
          this.toast.error(error.data.error.message);
          //localStorage.setItem("siteSuspended", true);
          //this.$router.push("/under-maintenance");
        });

      try {
        if (localStorage.getItem("accessToken")) {
          this.$store
            .dispatch("moduleMain/getGames", {})
            .then(() => {})
            .catch();
        }
      } catch {}
    },

    hubCall() {
      signalr.startConnection();
      signalr.addReceiveMessageListener((message) => {
        try {
          var jsonObj = JSON.parse(message);
          // console.log("message:", message);

          if (jsonObj && jsonObj?.Code == "UserBalance") {
            var mainData = JSON.parse(jsonObj["Data"]);

            if (localStorage.getItem("userinfo")) {
              // change userinfo in localStorage
              let userdata = JSON.parse(localStorage.getItem("userinfo"));
              userdata.balance = mainData?.Balance;
              userdata.exposure = mainData?.Exposure;

              localStorage.setItem("userinfo", JSON.stringify(userdata));

              //change OpenBets in localStorage
              let openBets = JSON.parse(localStorage.getItem("OpenBets"));
              openBets.balance = mainData?.Balance;
              openBets.exposure = mainData?.Exposure;

              localStorage.setItem("OpenBets", JSON.stringify(openBets));

              this.$store.commit(
                "moduleMain/SET_OPEN_BETS",
                JSON.parse(localStorage.getItem("OpenBets")),
                {
                  root: true,
                }
              );

              this.$store.commit("authModule/UPDATE_USER_INFO", userdata, {
                root: true,
              });

              this._BALANCE = userdata.balance;
              this._EXPOSURE = userdata.exposure;
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
  computed: {},
  mounted() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    this.hubCall();
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        var domain = "";
        if (
          this.$store.getters["configModule/domainSettings"] &&
          this.$store.getters["configModule/domainSettings"].domain
        )
          domain = this.$store.getters["configModule/domainSettings"].domain;

        document.title = to.meta.title
          ? `${domain != "" ? domain + " | " : ""}` + to.meta?.title
          : `${domain != "" ? domain + " | " : ""}`;

        // eventsHub2.update_page(to.fullPath);
      },
    },
    "$store.state.configModule.domainSettings"() {
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          this.$store.getters["configModule/domainSettings"]?.description
        );
    },
  },
  created() {
    this.getDomainSettingData();

    try {
      document.documentElement.setAttribute("theme", this.$store.getters.theme);
      document.body.setAttribute("theme", this.$store.getters.theme);

      auth.init();
      this.$store.dispatch("authModule/updateInReload").then().catch();
      main.init();
    } catch (e) {
      //console.log(e);
    }
  },
  beforeCreate() {
    //console.log("05");
  },
};
</script>

<style lang="scss" scoped></style>
<style>
.moveInUp-enter-active {
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.moveInUp-leave-active {
  animation: moveInUp 0.1s ease-in;
}

@keyframes moveInUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-400px);
  }
}
</style>
